
import {defineComponent, ref} from "vue";
import PermissionService from "@/core/services/PermissionService";
import {userRoleCheck} from "@/core/composite/composite";

export default defineComponent({
  name: "Permissions",
  props: {
    userId: {}
  },

  setup(props) {
    const loading = ref(false);
    const list = ref<any>()
    const functions = ref<any>([])
    const getList = () => {
      PermissionService.getList().then(res => {
        list.value = res;
      })
    }
    const getData = () => {
      PermissionService.getUserPermission(props.userId).then(res => {
        functions.value = res;
      })
    }
    getList();
    getData();
    return {
      loading,
      list,
      functions,
      ...userRoleCheck(),
    }
  },
  methods: {
    onChange($event) {
      this.loading = true;
      if ($event.target.checked) {
        PermissionService.addUserPermission(this.userId, $event.target.value).finally(() => {
          this.loading = false
        })
      } else {
        PermissionService.deleteUserPermission(this.userId, $event.target.value).finally(() => {
          this.loading = false
        })
      }
    }
  }
})
